<template>
  <div class="cover">
    <img :src="cover.img" alt="">
    <h1>{{ cover.title }}<span>{{ cover.description }}</span></h1>
  </div>
</template>

<script>
import eventBus from "vue3-eventbus";

export default {
  name: "Cover",
  data() {
    return {
      cover: {
        img: "https://www.nio.cn/themes/nioweb/images/testimonials/hero.jpg",
        title: "标题",
        description: "描述描述描述描述描述描述描述描述描述描述"
      }
    };
  },
  mounted() {
    const that = this;
    eventBus.on("cover", (val) => {
      that.cover = val;
    });
  }
};
</script>

<style scoped>

.cover {
  width: 100vw;
  position: relative;
}

.cover img {
  width: 100%;
}

.cover h1 {
  font-size: 42px;
  line-height: 42px;
  color: #FFFFFF;
  letter-spacing: 3px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-weight: 100;
}

.cover span {
  font-size: 24px;
  line-height: 16px;
  display: block;
  margin-top: 20px;
}
</style>