import request from "../utils/request";

export default {
  getArticleCate(website_id, cate_id) {
    return request({
      url: "/article/cate/" + website_id + "/" + cate_id,
      method: "get",
    });
  },
  getArticles(website_id, cate_id, page, limit) {
    return request({
      url:
        "/article/list/" +
        website_id +
        "/" +
        cate_id +
        "?page=" +
        page +
        "&limit=" +
        limit,
      method: "get",
    });
  },
  getArticle(website_id, id) {
    return request({
      url: "/article/item/" + website_id + "/" + id,
      method: "get",
    });
  },
};
